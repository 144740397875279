import React from "react";
import FlexWrapper from "../FlexWrapper";

const Region = () => {
  return (
    <section className="regiao" id="regiao">
      <div class="banner-pix"></div>
      <div className="container">
        <FlexWrapper>
          <div className="regiao-itens">
            <h2 className="subtitulo-verde">A Região</h2>
            <p>
              A Cidade de Deus surgiu como um dos projetos habitacionais criados
              para abrigar os moradores expulsos de favelas pela política
              remocionista do governo Carlos Lacerda (1960-1965).
            </p>
            <p>
              Na década de 1980, o bairro já tinha ex-moradores de 63 favelas do
              Rio de Janeiro. Cerca de 70% dessa população vinha das favelas
              Praia do Pinto, Parque da Gávea, Ilha das Dragas, Parque do
              Leblon, Catacumba e Rocinha. Hoje, a população é de 36.515
              habitantes. Destes, 9.505 (26%) são jovens. Em 2002, as imagens da
              favela ganharam o mundo após a estreia do filme Cidade de Deus,
              dirigido por Fernando Meireles. O longa, baseado num romance do
              escritor Paulo Lins, conta a história de como o comércio ilegal de
              drogas se instalou no local.
            </p>
            <p>
              A produção é considerada a primeira de uma linhagem de filmes
              brasileiros sobre pobreza e violência urbana, que marcaram a
              primeira década dos anos 2000.
            </p>
          </div>
          <div className="regiao-itens">
            <p>VEJA RIO - 16/02/2018</p>
            <div className="regiao-itens-foto">
              <h3>Cidade</h3>
              <h4>de</h4>
              <h5>deus</h5>
            </div>
          </div>
        </FlexWrapper>
      </div>
    </section>
  );
};

export default Region;
